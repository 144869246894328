import { useState, useEffect } from 'react';
import ButtonPrimary from '../../components/style-components/ButtonPrimary';
import { useDispatch, useSelector } from 'react-redux';
import { createCoupon, reset, getCouponById, updateCoupon } from '../../features/coupons/couponSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getProducts } from '../../features/products/productSlice';

function AdminAddCoupon() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { couponId } = useParams();
    const { coupon, isError, isLoading, message } = useSelector((state) => state.coupon);
    const { products } = useSelector((state) => state.product);

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
      if (!user) {
        navigate('/');
      }
    }, [user, navigate]);

    const [code, setCode] = useState('');
    const [active, setActive] = useState(true);
    const [delivery, setDelivery] = useState(true);
    const [discountAmount, setDiscountAmount] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [productList, setProductList] = useState([]);
    const [useLimit, setUseLimit] = useState(null);

    useEffect(() => {
        if (isError) {
            toast.error(message);
        }

        if (coupon) {
            setCode(coupon.code);
            setActive(coupon.active);
            setDelivery(coupon.delivery);
            setDiscountAmount(coupon.discountAmount);
            setDiscountPercentage(coupon.discountPercentage);
            setExpiryDate(coupon.expiryDate.split('T')[0]);
            setProductList(coupon.products ? coupon.products.map(product => product._id) : []);
            setUseLimit(coupon.useLimit);
        }

        return () => {
            dispatch(reset());
        };
    }, [coupon, message, dispatch, isError]);

    useEffect(() => {
        if (couponId) {
            dispatch(getCouponById(couponId));
        }
    }, [couponId, dispatch]);

    useEffect(() => {
        dispatch(getProducts());
    }, [dispatch]);

    const validateCoupon = () => {
        if (!code) {
            toast.error('Kupona kods ir obligāts');
            return false;
        }
        if (!discountAmount && !discountPercentage) {
            toast.error('Atlaide ir obligāta');
            return false;
        }
        if (!expiryDate) {
            toast.error('Derīguma termiņš ir obligāts');
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateCoupon()) {
            return;
        }
        const couponData = {
            _id: couponId,
            code,
            active,
            delivery,
            discountAmount,
            discountPercentage,
            expiryDate,
            products: productList.length > 0 ? productList : null,
            useLimit
        };

        if (couponId) {
            dispatch(updateCoupon(couponData));
        } else {
            dispatch(createCoupon(couponData));
        }
        navigate('/edit-coupons');
    };

    return (
        <div className='add-coupon'>
            <ButtonPrimary text="Atpakaļ" onClick={() => navigate('/edit-coupons')}/>
            <h2 className='light padding-20-top'>{couponId ? "Rediģēt kuponu" : "Pievienot kuponu"}</h2>
            <form className='couponForm padding-20-top' onSubmit={handleSubmit}>
                <div className="product-checkbox">
                    <input className='checkbox' type="checkbox" checked={active} onChange={(e) => setActive(e.target.checked)}/>
                    <label>Aktīvs</label>
                </div>
                <div className="product-checkbox">
                    <input className='checkbox' type="checkbox" checked={delivery} onChange={(e) => setDelivery(e.target.checked)}/>
                    <label>Bezmaksas piegāde</label>
                </div>
                <input
                    type="text"
                    placeholder="Code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                />
                <input
                    type="number"
                    placeholder="Summas atlaide"
                    value={discountAmount}
                    onChange={(e) => setDiscountAmount(e.target.value)}
                />
                <input
                    type="number"
                    placeholder="Procentuāla atlaide"
                    value={discountPercentage}
                    onChange={(e) => setDiscountPercentage(e.target.value)}
                />
                <input
                    type="date"
                    value={expiryDate}
                    onChange={(e) => setExpiryDate(e.target.value)}
                />
                <input type="number" placeholder="Atļautais lietojuma skaits (tukšs = bez limita)" value={useLimit} onChange={(e) => setUseLimit(e.target.value)}/>
                <label className='bold padding-10-top'>Atļautie produkti:</label>
                {products && products.map((product) => (
                    <div key={product._id} className="product-checkbox">
                        <input
                            type="checkbox"
                            checked={productList.includes(product._id)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setProductList([...productList, product._id]);
                                } else {
                                    setProductList(productList.filter(id => id !== product._id));
                                }
                            }}
                        />
                        <label>{product.title}</label>
                    </div>
                ))}
                
                <ButtonPrimary text={couponId ? "Saglabāt kuponu" : "Pievienot kuponu"} type="submit"/>
                <div className="padding-10-bottom"></div>
            </form>
        </div>
    );
}

export default AdminAddCoupon;



