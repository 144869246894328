function ButtonPrimary({children, text, onClick, type}) {
  return (
    <div className="flex">
      <button className={`primary-button ${type}`} onClick={onClick}>
        <span className="icon">{children}</span>
        <span className="text" style={children ? {marginLeft:"10px"} : {}}>{text}</span>
      </button>
    </div>
    
  )
}

export default ButtonPrimary