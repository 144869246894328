import { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import ButtonPrimary from "../style-components/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import SelectDropdown from "../SelectDropdown";
import Confirmation from '../Confirmation';
import { deleteProduct, permDeleteProduct } from "../../features/products/productSlice";
import { useDispatch } from "react-redux";

function ProductList({ products, trash }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [displayConfirm, setDisplayConfirm] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  const [productList, setProductList] = useState(products);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");

  useEffect(() => {
    setProductList(products);
  }, [products]);

  const categories = [...new Set(products.map((product) => product.category))];
  const titles = [...new Set(products.map((product) => product.title))];

  const handleSearch = () => {
    if (selectedCategory && selectedTitle) {
      setProductList(products.filter((product) => product.category === selectedCategory && product.title === selectedTitle));
    } else if (selectedCategory) {
      setProductList(products.filter((product) => product.category === selectedCategory));
    } else if (selectedTitle) {
      setProductList(products.filter((product) => product.title === selectedTitle));
    } else {
      setProductList(products);
    }
  };

  const resetFilters = () => {
    setSelectedCategory("");
    setSelectedTitle("");
    setProductList(products);
  };

  const handleDeleteClick = (id) => {
    setProductToDelete(id);
    setDisplayConfirm(true);
  };

  const confirmDelete = () => {
    if (productToDelete) {
      if(trash) {
        dispatch(permDeleteProduct(productToDelete));
      } else {
        dispatch(deleteProduct(productToDelete));
      }
      setProductToDelete(null);
    }
    setDisplayConfirm(false);
  };

  const getVariationMinPrice = (product) => {
    if (!product.variants) return 0;
    return Math.min(...product.variants.map((variant) => variant.price));
  };

  const getVariationMaxPrice = (product) => {
    if (!product.variants) return 0;
    return Math.max(...product.variants.map((variant) => variant.price));
  };

  const getVariationMinCount = (product) => {
    if (!product.variants) return 0;
    return Math.min(...product.variants.map((variant) => variant.countInStock));
  };

  const getVariationMaxCount = (product) => {
    if (!product.variants) return 0;
    return Math.max(...product.variants.map((variant) => variant.countInStock));
  };

  if (!products) return null;

  return (
    <div style={{ width: "100%" }}>
      {displayConfirm && (
        <Confirmation
          message="Vai esi pārliecināts?"
          onConfirm={confirmDelete}
          onCancel={() => setDisplayConfirm(false)}
        />
      )}

      <h2 className="light">{trash ? "Izdzēsto " : ""}Produktu Saraksts:</h2>
      <div className="admin-product-control-panel flex gap-10">
        <SelectDropdown
          placeholder="Kategorija"
          setSelectedValue={setSelectedCategory}
          values={categories}
          value={selectedCategory}
        />
        <SelectDropdown
          placeholder="Nosaukums"
          setSelectedValue={setSelectedTitle}
          values={titles}
          value={selectedTitle}
        />
        <ButtonPrimary text="Meklēt" onClick={handleSearch}/>
        <ButtonPrimary text="Notīrīt" onClick={resetFilters}/>
      </div>
      <div className="admin-product-control-panel flex gap-10 padding-10-bottom">
        <ButtonPrimary text="Pievienot Produktu" onClick={() => navigate("/edit-products/add")}/>
        <ButtonPrimary text="Ievadīt ražu" onClick={() => navigate("/enter-harvest")}/>
        <ButtonPrimary text="Ievadīt daudzumu" onClick={() => navigate("/edit-stock")}/>
        <ButtonPrimary text={trash ? "Atpakaļ" : "Atkritne"} onClick={trash ? () => navigate("/edit-products") : () => navigate("/edit-products/trash")}/>
      </div>

      <table className="admin-edit-products-table">
        <thead>
          <tr>
            <th>Attēls</th>
            <th style={{ textAlign: "left" }}>Nosaukums</th>
            <th style={{ textAlign: "left" }}>Kategorija</th>
            <th style={{ textAlign: "left" }}>Cena</th>
            <th style={{ textAlign: "left" }}>Daudzums</th>
            <th>Dzēst</th>
          </tr>
        </thead>
        <tbody>
          {productList.map((item, index) => (
            <tr key={index}>
              <td
                className="image"
                onClick={() => navigate(`/edit-products/${item._id}`)}
              >
                <img src={item.image.src} alt={item.name} />
              </td>
              <td
                className="title"
                onClick={() => navigate(`/edit-products/${item._id}`)}
              >
                {item.title}
              </td>
              <td
                className="category"
                onClick={() => navigate(`/edit-products/${item._id}`)}
              >
                {item.category}
              </td>
              <td
                className="price"
                onClick={() => navigate(`/edit-products/${item._id}`)}
              >
                {getVariationMinPrice(item) === getVariationMaxPrice(item) ? getVariationMinPrice(item) : `${getVariationMinPrice(item)} - ${getVariationMaxPrice(item)}`}€
              </td>
              <td
                className="stock"
                onClick={() => navigate(`/edit-products/${item._id}`)}
              >
                {getVariationMinCount(item) === getVariationMaxCount(item) ? getVariationMinCount(item) : `${getVariationMinCount(item)} - ${getVariationMaxCount(item)}`}

              </td>
              <td className="remove">
                <FaTimes
                  className="cart-remove-button"
                  size={20}
                  onClick={() => handleDeleteClick(item._id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ProductList;