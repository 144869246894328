import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleStatusMultiple, fetchShippingLabels, exportOrders } from '../../features/orders/orderSlice';
import ButtonPrimary from '../style-components/ButtonPrimary';
import { toast } from 'react-toastify';
import { MdOutlineLocalShipping, MdMoneyOff, MdOutlineAttachMoney } from "react-icons/md";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { FaRegFileExcel } from "react-icons/fa";

import Spinner from '../Spinner';



function OrderList({ orders, labels,  unpaid }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isLoading } = useSelector(state => state.order);

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [onlyNewOrders, setOnlyNewOrders] = useState(false);
    const [onlyProcessingOrders, setOnlyProcessingOrders] = useState(false);

    const selectAll = (e) => {
        if (e.target.checked) {
            setSelectedOrders(filteredOrders.map(order => order._id));
        } else {
            setSelectedOrders([]);
        }
    };

    const selectOrder = (e, id) => {
        if (e.target.checked) {
            setSelectedOrders([...selectedOrders, id]);
        } else {
            setSelectedOrders(selectedOrders.filter(orderId => orderId !== id));
        }
    };

    const getDateString = (date) => {
        const d = new Date(date);
        return `${d.getDate().toString().padStart(2, '0')}.${(d.getMonth() + 1).toString().padStart(2, '0')}.${d.getFullYear()}`;
    };

    const getStatusString = (status) => {
        switch (status) {
            case 'unpaid': return 'Neapmaksāts';
            case 'new': return 'Jauns pasūtījums';
            case 'processing': return 'Apstrādē';
            case 'shipped': return 'Nosūtīts';
            case 'cancelled': return 'Atcelts';
            default: return '';
        }
    };

    const downloadPdf = (label, fileName = 'document.pdf') => {
        const data = label.data;

        // Convert byte array to Uint8Array
        const byteArray = new Uint8Array(data);

        // Create a Blob with PDF MIME type
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Set the file name
        document.body.appendChild(link);
        link.click(); // Trigger the download
        link.parentNode.removeChild(link); // Clean up
        window.URL.revokeObjectURL(url); // Clean up the URL object
    };

    const handleToggleProcessing = () => {
        if (selectedOrders.length === 0) return;
        const data = {
            ids: selectedOrders,
            status: 'processing'
        };
        dispatch(toggleStatusMultiple(data));
        dispatch(fetchShippingLabels(selectedOrders));


        // // for every selected order download card
        // selectedOrders.forEach(orderId => {
            
        //     const order = orders.find(order => order._id === orderId);
        //     downloadPdf(order, `Ceļa karte ${order.client.firstName} ${order.client.lastName}.pdf`);
        // });
    };

    function handleToggleExport() {
        if (selectedOrders.length === 0) return;
        dispatch(exportOrders(selectedOrders));
    }

    const handleToggleShipped = () => {
        if (selectedOrders.length === 0) return;
        const data = {
            ids: selectedOrders,
            status: 'shipped'
        };
        dispatch(toggleStatusMultiple(data));
    };

    const filteredOrders = orders.filter(order => {
        if (onlyNewOrders && onlyProcessingOrders) return false;
        if (onlyNewOrders && order.status !== 'new') return false;
        if (onlyProcessingOrders && order.status !== 'processing') return false;
        return true;
    });

    useEffect(() => {
        for (const label of labels) {
            downloadPdf(label, `Ceļa karte ${label.client.firstName} ${label.client.lastName}.pdf`);
        }
    }, [labels]);


    if (!orders || isLoading) return <Spinner />;

    return (
        <div style={{ width: "100%" }} className='padding-20-bottom'>
            <h2 className='light'>Pasūtījumu Saraksts:</h2>
            <div className="order-controls padding-20-top flex gap-10 space-between">
                <div className="flex column gap-10">
                    <div className="flex gap-10">
                        <ButtonPrimary onClick={handleToggleExport}><FaRegFileExcel size={15} style={{marginBottom: "-3px", paddingRight: "10px"}}/>Eksportēt</ButtonPrimary>
                        <ButtonPrimary onClick={handleToggleProcessing}><IoCloudDownloadOutline size={15} style={{marginBottom: "-3px", paddingRight: "10px"}}/>Lejupielādēt ceļa karti</ButtonPrimary>
                        <ButtonPrimary onClick={handleToggleShipped}><MdOutlineLocalShipping size={15} style={{marginBottom: "-3px", paddingRight: "10px"}}/> Atzīmēt kā izsūtīts</ButtonPrimary>
                    </div>
                    <div className="flex gap-10 column">
                        <label>
                            <input
                                type="checkbox"
                                checked={onlyNewOrders}
                                onChange={() => setOnlyNewOrders(!onlyNewOrders)}
                            />
                            <span className="padding-10-left">Tikai jauni pasūtījumi</span>
                            
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={onlyProcessingOrders}
                                onChange={() => setOnlyProcessingOrders(!onlyProcessingOrders)}
                            />
                            <span className="padding-10-left">Tikai apstrādē</span>
                            
                        </label>
                    </div>
                </div>
                <div className="flex gap-10">
                    <ButtonPrimary onClick={() => unpaid ? navigate("/edit-orders") : navigate("/unpaid-orders")}>
                        {unpaid ? <MdOutlineAttachMoney size={15} style={{marginBottom: "-3px", paddingRight: "10px"}}/> : <MdMoneyOff size={15} style={{marginBottom: "-3px", paddingRight: "10px"}}/>}
                        {unpaid ? "Atpakaļ uz apmaksātajiem" : "Apskatīt neapmaksātos"}
                    </ButtonPrimary>
                </div>
            </div>
            <div className="flex space-between">
                <p className='padding-20-top'>Atzīmēti pasūtījumi: {selectedOrders.length}</p>
            </div>
            <table className='admin-edit-orders-table padding-10-top'>
                <thead>
                    <tr>
                        <th className='checkbox'><input type="checkbox" onChange={selectAll} checked={selectedOrders.length === filteredOrders.length} /></th>
                        <th>Vārds Uzvārds</th>
                        <th>Preces</th>
                        <th>Datums</th>
                        <th>Statuss</th>
                        <th>Cena</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredOrders.map((order, index) => (
                        <tr key={index}>
                            <td className='checkbox'><input type="checkbox" checked={selectedOrders.includes(order._id)} onChange={(e) => selectOrder(e, order._id)} /></td>
                            <td className='title' onClick={() => navigate(`/edit-orders/${order._id}`)}>{order.client.firstName} {order.client.lastName}</td>
                            <td className='items'>
                                <ul>
                                    {order.orderItems.map((item, index) => (
                                        <li key={index}>
                                            <span className={item.quantity !== 1 ? "red-text" : ""}>{item.quantity}x</span> {item.name} ({item.variant_name})
                                        </li>                                        
                                    ))}
                                </ul>
                            </td>
                            <td className='date'>{getDateString(order.createdAt)}</td>
                            <td className='status'>{getStatusString(order.status)}</td>
                            <td className='price'>€{unpaid ? order.totalPrice.toFixed(2) : order.payment.amount.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default OrderList;
