import ButtonPrimary from "../style-components/ButtonPrimary";

function VariantForm({ handleSubmit, variant, handleVariantChange, saveVariant, removeVariant, index }) {
  return (
    <div className="variant-container padding-20-top">
      {index !== -1 ? <h5 className="light">{index + 1}. izmērs: <b>{variant.size}</b></h5> : <h5 className="light">Jauns Izmērs</h5>}
      <div className="form-group double">
        <div className="product-input-container">
          <label>Size</label>
          <input
            type="text"
            value={variant.size}
            placeholder="Size (1kg, 2kg)"
            onChange={(e) =>
              handleVariantChange(index !== -1 ? index : "new", "size", e.target.value)
            }
          />
        </div>
        <div className="product-input-container">
          <label>Price</label>
          <input
            type="number"
            value={variant.price}
            placeholder="Price"
            onChange={(e) =>
              handleVariantChange(index !== -1 ? index : "new", "price", e.target.value)
            }
          />
        </div>
      </div>
      <div className="form-group double">
        <div className="product-input-container">
          <label>Discount Price</label>
          <input
            type="number"
            placeholder="Discount Price"
            value={variant.discount_price}
            onChange={(e) =>
              handleVariantChange(index !== -1 ? index : "new", "discount_price", e.target.value)
            }
          />
        </div>
        <div className="product-input-container">
          <label>Count In Stock</label>
          <input
            type="number"
            placeholder="Count In Stock"
            value={variant.countInStock}
            onChange={(e) =>
              handleVariantChange(index !== -1 ? index : "new", "countInStock", e.target.value)
            }
          />
        </div>
      </div>
      <div className="product-input-container">
        <label>Weight</label>
        <input
          type="number"
          placeholder="Weight"
          value={variant.weight}
          onChange={(e) =>
            handleVariantChange(index !== -1 ? index : "new", "weight", e.target.value)
          }
        />
      </div>
      <div className="form-group tripple">
        <div className="product-input-container">
          <label>Length</label>
          <input
            type="number"
            placeholder="Length"
            value={variant.length}
            onChange={(e) =>
              handleVariantChange(index !== -1 ? index : "new", "length", e.target.value)
            }
          />
        </div>
        <div className="product-input-container">
          <label>Height</label>
          <input
            type="number"
            placeholder="Height"
            value={variant.height}
            onChange={(e) =>
              handleVariantChange(index !== -1 ? index : "new", "height", e.target.value)
            }
          />
        </div>
        <div className="product-input-container">
          <label>Width</label>
          <input
            type="number"
            placeholder="Width"
            value={variant.width}
            onChange={(e) =>
              handleVariantChange(index !== -1 ? index : "new", "width", e.target.value)
            }
          />
        </div>
      </div>
      
      <div className="button-container padding-20-top"> 
        {index !== -1 ? 
        <div className="flex gap-10">
          <ButtonPrimary text="Dzēst izmēru" onClick={(e) => {
              e.preventDefault();
              removeVariant(index);
          }} />
          <ButtonPrimary text="Saglabāt" onClick={handleSubmit} />
        </div>
        :
        <ButtonPrimary text="Saglabāt izmēru" onClick={(e) => {
            e.preventDefault();
            saveVariant();
          }} />
        }
      </div>
    </div>
  );
}

export default VariantForm;

