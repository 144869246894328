import { useState, useEffect } from 'react';
import ButtonPrimary from "./style-components/ButtonPrimary";
import { toast } from 'react-toastify';

function ProductContent({ product, handleAddToCart }) {
  // Automatically select the first variant
  const [selectedVariant, setSelectedVariant] = useState(product.variants[0]);

  const cartHandler = () => {
    if (!selectedVariant) {
      toast.error('Lūdzu izvēlies izmēru!');
    } else {
      const product_object = { ...product, variant: selectedVariant };
      handleAddToCart(product_object);
    }
  }

  return (
    <div className="product-content">
      <h1>{product.title}</h1>
      <p className='product-description padding-20-top'>{product.description}</p>
      <div className="product-info padding-20-top">
        <p><span className="bold">Augšanas ilgums: </span>{product.grow_time_min} - {product.grow_time_max} nedēļas </p>
        <p><span className="bold">Augšanas temperatūra: </span>{product.grow_temp_min} - {product.grow_temp_max} °C</p>
        <p><span className="bold">Uzglabāšanas termiņš: </span>{product.valid_for} mēneši</p>
      </div>

      {selectedVariant && 
      <div>
        {(selectedVariant.countInStock < 6 && selectedVariant.countInStock > 1) && <p className="low-stock padding-20-top">Pēdējās {selectedVariant.countInStock} preces!</p>}
        {selectedVariant.countInStock === 1 && <p className="low-stock padding-20-top">Pēdējā prece noliktavā!</p>}
        <p className={`product-price padding-20-top padding-10-bottom`}>
          <span className={`${selectedVariant.discount_price ? 'discounted' : null}`}>€{selectedVariant.price}</span>
          <span>{selectedVariant.discount_price && `€${selectedVariant.discount_price}`}</span>
        </p>
        {selectedVariant.countInStock < 1 ? <p className="out-of-stock padding-20-top">Nav noliktavā</p> : <ButtonPrimary 
          text="Pievienot grozam" 
          onClick={cartHandler} 
          disabled={selectedVariant.countInStock < 1} 
          className={`add-to-cart-button ${selectedVariant.countInStock < 1 ? 'out-of-stock' : ''}`}
        />}
        
      </div>}
    </div>
  );
}

export default ProductContent;
