import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuItem from './style-components/MenuItem';
import { IoCartOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import CartCounter from './style-components/CartCounter';
import HeaderClient from './header-components/HeaderClient';
import HeaderAdmin from './header-components/HeaderAdmin';

function Header() {
    const [admin, setAdmin] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth < 700);
    const [isHome, setIsHome] = useState(false);

    const location = useLocation();

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
      if (user && user.type === 'admin') {
        setAdmin(true);
      }
    }, [user]);

    useEffect(() => {
      const handleResize = () => {
        setMobile(window.innerWidth < 1100);
      };

      // Attach the event listener
      window.addEventListener('resize', handleResize);

      // Cleanup function
      return () => {
        // Remove the event listener
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
      // Check if the current path is the home path
      setIsHome(location.pathname === '/');
    }, [location]);

    return (
        <div className={`header ${isHome && !admin ? 'light' : ''}`}>
          {admin ? <HeaderAdmin /> : <HeaderClient mobile={mobile} />}
        </div>
    );
}

export default Header;
