import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { app } from "../../firebase";
import { ref, uploadBytesResumable, getStorage } from "firebase/storage";
import imageCompression from "browser-image-compression";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner";
import ButtonPrimary from "../../components/style-components/ButtonPrimary";
import ProductCard from "../../components/ProductCard";
import { FaTimes } from "react-icons/fa";
import {
  addProduct,
  editProduct,
  getProductByIdAdmin,
  renewProduct,
  reset,
} from "../../features/products/productSlice";
import VariantForm from "../../components/admin/VariantForm";

function AdminAddProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storage = getStorage(app);

  const { productId } = useParams();
  const { product, isLoading, isError, message } = useSelector(
    (state) => state.product
  );

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user, navigate]);

  const [addNewVariant, setAddNewVariant] = useState(false);

  const [newVariant, setNewVariant] = useState({
    size: "",
    price: null,
    discount_price: null,
    countInStock: null,
    weight: null,
    height: null,
    width: null,
    length: null,
    grow_time_min: null,
    grow_time_max: null,
    grow_temp_min: null,
    grow_temp_max: null,
    valid_for: null,
  });

  const singleFileInputRef = useRef(null);
  const multipleFileInputRef = useRef(null);

  const [gallery, setGallery] = useState([]);
  const [newImageFiles, setNewImageFiles] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [image, setImage] = useState({});
  const [title, setTitle] = useState("");
  const [latinTitle, setLatinTitle] = useState("");
  const [category, setCategory] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [desc, setDesc] = useState("");
  const [growTimeMin, setGrowTimeMin] = useState(null);
  const [growTimeMax, setGrowTimeMax] = useState(null);
  const [growTempMin, setGrowTempMin] = useState(null);
  const [growTempMax, setGrowTempMax] = useState(null);
  const [validFor, setValidFor] = useState(null);

  const [variants, setVariants] = useState([]);

  const [productObject, setProductObject] = useState({});
  const [photoEditted, setPhotoEditted] = useState(false);
  const [galleryEditted, setGalleryEditted] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (productId) {
      dispatch(getProductByIdAdmin(productId));
    } else {
      // Initialize all state variables for adding a new product
      setTitle("");
      setLatinTitle("");
      setCategory("");
      setShortDesc("");
      setDesc("");
      setImage({});
      setGallery([]);
      setGrowTimeMin(null);
      setGrowTimeMax(null);
      setGrowTempMin(null);
      setGrowTempMax(null);
      setValidFor(null);
      setVariants([]);
    }

    return () => {
      dispatch(reset());
    };
  }, [productId]);

  useEffect(() => {
    if (product && productId) {
      setTitle(product.title);
      setLatinTitle(product.latin_title);
      setCategory(product.category);
      setShortDesc(product.short_description);
      setDesc(product.description);
      setImage(product.image);
      setGallery(product.images || []);
      setGrowTimeMin(product.grow_time_min);
      setGrowTimeMax(product.grow_time_max);
      setGrowTempMin(product.grow_temp_min);
      setGrowTempMax(product.grow_temp_max);
      setValidFor(product.valid_for);
      setVariants(product.variants || []);
    }
  }, [product, productId]);

  useEffect(() => {
    setProductObject({
      title,
      latin_title: latinTitle,
      category,
      short_description: shortDesc,
      description: desc,
      image,
      images: gallery,
      grow_time_min: growTimeMin,
      grow_time_max: growTimeMax,
      grow_temp_min: growTempMin,
      grow_temp_max: growTempMax,
      valid_for: validFor,
      variants,
    });
  }, [title, latinTitle, category, shortDesc, desc, image, gallery, growTempMin, growTimeMax, growTempMax, growTimeMax, variants]);

  const handleSingleFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);

    if (file) {
      setPhotoEditted(true);
      const url = URL.createObjectURL(file);
      setImage({ src: url, name: file.name });
    }
  };

  const handleMultipleFileChange = (e) => {
    const files = e.target.files;
    const newFiles = Array.from(files);
    setNewImageFiles(newFiles);

    const newGallery = [...gallery];
    for (let i = 0; i < newFiles.length; i++) {
      setGalleryEditted(true);
      const file = newFiles[i];
      const url = URL.createObjectURL(file);
      newGallery.push({ src: url, name: file.name, isNew: true });
    }
    setGallery(newGallery);
  };

  const handleImageDelete = (index) => {
    setGalleryEditted(true);
    const newGallery = gallery.filter((image, i) => i !== index);
    setGallery(newGallery);

    const newNewImageFiles = newImageFiles.filter((_, i) => i !== index);
    setNewImageFiles(newNewImageFiles);
  };

  const uploadImage = async (file, originalName) => {
    try {
      if (file == null) return;

      const imageName = v4();
      const maxWidthOrHeight = 800;

      const compressedFile = await imageCompression(file, {
        maxWidthOrHeight,
        useWebWorker: true,
      });

      const imageRef = ref(storage, `product_images/${imageName}`);
      const uploadTask = uploadBytesResumable(imageRef, compressedFile);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            toast.error("Something went wrong with image upload");
            reject(error);
          },
          () => {
            toast.success("Image uploaded successfully");
            resolve(imageName);
          }
        );
      });
    } catch (error) {
      toast.error("Something went wrong with image compression");
      throw error;
    }
  };

  const uploadGallery = async (files) => {
    const names = [];
    for (const file of files) {
      const name = await uploadImage(file, file.name);
      names.push(name);
    }
    return names;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    let updatedProduct = { ...productObject };

    if (photoEditted) {
      const imageName = await uploadImage(imageFile, image.name);
      updatedProduct.image = imageName;
    } else {
      updatedProduct.image = productId
        ? product.image.name
        : updatedProduct.image.name;
    }

    if (galleryEditted) {
      const newGalleryImages = await uploadGallery(newImageFiles);
      const existingGalleryImages = gallery
        ? gallery.filter((image) => !image.isNew).map((image) => image.name)
        : [];
      const allGalleryImages = [...existingGalleryImages, ...newGalleryImages];
      updatedProduct.images = allGalleryImages;
    } else {
      updatedProduct.images = productId
        ? product.images.map((image) => image.name)
        : updatedProduct.images
        ? updatedProduct.images.map((image) => image.name)
        : [];
    }

    if (productId) {
      dispatch(editProduct({ ...updatedProduct, _id: productId }));
    } else {
      dispatch(addProduct(updatedProduct));
    }

    setLoading(false);
  };

  const handleProductRenew = () => {
    dispatch(renewProduct(productId));
    navigate("/edit-products");
  };

  const handleVariantChange = (index, key, value) => {
    const newVariants = [...variants];
    newVariants[index] = { ...newVariants[index], [key]: value };
    setVariants(newVariants);
  };

  const handleNewVariantChange = (placeholder, key, value) => {
    setNewVariant((prevVariant) => ({ ...prevVariant, [key]: value }));
  };  

  const saveNewVariant = () => {
    if(!newVariant.size || !newVariant.price || !newVariant.countInStock || !newVariant.weight) {
      toast.error("Lūdzu aizpildi visus laukus");
      return;
    } 
    setVariants([...variants, newVariant]);
    setNewVariant({
      size: "",
      price: null,
      discount_price: null,
      countInStock: null,
      weight: null,
      height: null,
      width: null,
      length: null,
    });
    setAddNewVariant(false);

  };

  const removeVariant = (index) => {
    setVariants(variants.filter((_, i) => i !== index));
  };

  if (isLoading || loading) return <Spinner />;

  return (
    <div className="add-product-page">
      <div className="add-product">
        <div className="add-product-form">
          <h2 className="light padding-20-bottom">
            {productId ? "Rediģēt produktu" : "Pievienot produktu"}
          </h2>
          {product && product.status === "inactive" && (
            <div className="flex padding-10-bottom">
              <ButtonPrimary
                text="Renew Product"
                onClick={handleProductRenew}
              />
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="product-input-container">
              <label>Nosaukums</label>
              <input
                className="title"
                type="text"
                placeholder="Nosaukums"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="product-input-container">
              <label>Latīniskais nosaukums</label>
              <input
                className="title"
                type="text"
                placeholder="Latīniskais nosaukums"
                value={latinTitle}
                onChange={(e) => setLatinTitle(e.target.value)}
              />
            </div>
            <div className="product-input-container">
              <label>Kategorija</label>
              <input
                className="category"
                type="text"
                placeholder="Kategorija"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </div>
            <div className="product-input-container">
              <label>Short Description</label>
              <textarea
                className="short-desc"
                type="text"
                placeholder="Īsais apraksts"
                value={shortDesc}
                onChange={(e) => setShortDesc(e.target.value)}
              />
            </div>
            <div className="product-input-container">
              <label>Description</label>
              <textarea
                className="desc"
                type="text"
                placeholder="Apraksts"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </div>
            <div className="form-group double">
              <div className="product-input-container">
                <label>Augšanas ilgums (no)</label>
                <input
                  type="number"
                  value={growTimeMin}
                  placeholder="Augšanas ilgums (no)"
                  onChange={(e) => setGrowTimeMin(Number(e.target.value))}
                />
              </div>
              <div className="product-input-container">
                <label>Augšanas ilgums (līdz)</label>
                <input
                  type="number"
                  placeholder="Augšanas ilgums (līdz)"
                  value={growTimeMax}
                  onChange={(e) => setGrowTimeMax(Number(e.target.value))}

                />
              </div>
            </div>
            <div className="form-group double">
              <div className="product-input-container">
                <label>Augšanas temperatūra (no)</label>
                <input
                  type="number"
                  placeholder="Augšanas temperatūra (no)"
                  value={growTempMin}
                  onChange={(e) => setGrowTempMin(Number(e.target.value))}

                />
              </div>
              <div className="product-input-container">
                <label>Augšanas temperatūra (līdz)</label>
                <input
                  type="number"
                  placeholder="Augšanas temperatūra (līdz)"
                  value={growTempMax}
                  onChange={(e) => setGrowTempMax(Number(e.target.value))}

                />
              </div>
            </div>
            <div className="product-input-container">
              <label>Derīguma termiņš</label>
              <input
                type="number"
                placeholder="Derīguma termiņš"
                value={validFor}
                onChange={(e) => setValidFor(Number(e.target.value))}

              />
            </div>
            <input
              type="file"
              ref={singleFileInputRef}
              style={{ display: "none" }}
              onChange={handleSingleFileChange}
            />
            <input
              type="file"
              multiple
              ref={multipleFileInputRef}
              style={{ display: "none" }}
              onChange={handleMultipleFileChange}
            />

            <div className="variant-section padding-20-top">
              <h4 className="light">Produkta izmēri</h4>
              {variants.map((variant, index) => (
                <VariantForm handleSubmit={handleSubmit} variant={variant} handleVariantChange={handleVariantChange} saveVariant={() => {}} removeVariant={removeVariant} index={index} />
              ))}
              
              {addNewVariant && (
                <VariantForm handleSubmit={handleSubmit} variant={newVariant} handleVariantChange={handleNewVariantChange} saveVariant={saveNewVariant} removeVariant={() => {}} index={-1}/>
              )}
              
            </div>
          </form>
          <div className="button-container padding-20-top">
            <ButtonPrimary text={addNewVariant ? "Atcelt" : "Jauns izmērs"} onClick={() => setAddNewVariant(!addNewVariant)} />
          </div>
          
        </div>
        
        <div className="add-product-card-container">
          
          <div className="add-product-card">
            <ProductCard product={productObject} admin={true}/>
          </div>

          <div className="added-image-gallery">
            {gallery.map((image, index) => (
              <div className="added-gallery-image">
                <img
                  src={image.src}
                  alt={"product.title"}
                  key={index}
                  className={'product-image'}
                />
                <span
                    className="admin-remove-image"
                    onClick={() => handleImageDelete(index)}
                  >
                    <FaTimes />
                  </span>
              </div>
              
            ))}
          </div>

          <div className="flex padding-40-top">
            <div
              className="button-container padding-20-right"
              onClick={() => singleFileInputRef.current.click()}
            >
              <ButtonPrimary text="Galvenā bilde" />
            </div>
            <div
              className="button-container padding-20-right"
            >
              <ButtonPrimary text="Galerija" onClick={() => multipleFileInputRef.current.click()}/>
            </div>
            <div className="button-container" onClick={handleSubmit}>
              <ButtonPrimary text="Saglabāt izmaiņas" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminAddProduct;
