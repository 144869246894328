import { useState, useEffect } from 'react';
import tutorialpdf from '../img/instrukcija.pdf';
import bloka_pirmais from '../img/bloka_pirmais.png';
import bloka_otrais from '../img/bloka_otrais.png';
import bloka_tresais from '../img/bloka_tresais.png';
import ButtonPrimary from '../components/style-components/ButtonPrimary'
import { Helmet } from 'react-helmet';

const Instrukcija = () => {

    const [mobile, setMobile] = useState(window.innerWidth < 700);

    useEffect(() => {
      const handleResize = () => {
        setMobile(window.innerWidth < 1100);
      };

      // Attach the event listener
      window.addEventListener('resize', handleResize);

      // Cleanup function
      return () => {
        // Remove the event listener
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
        <div className="instrukcija-page">
            <Helmet>
            <title>Nora Spora - Instrukcija</title>
            <meta name="description" content="Atklāj savu iekšējo mikologu kopā ar Nora Spora! Lejupielādē instrukciju un skatīties video instrukciju par sēņu audzēšanu mājās." />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>

            <h2 className="light">Atklāj savu iekšējo mikologu kopā ar Nora Spora!</h2>

                <div className="flex gap-10 padding-10-top">
                <ButtonPrimary text="Lejupielādēt Instrukciju" onClick={() => {
                    const link = document.createElement('a');
                    link.href = tutorialpdf;
                    link.download = 'instrukcija.pdf';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }} />
                <ButtonPrimary text="Video Instrukcija" onClick={() => {
                    window.open('https://youtube.com/shorts/GBouZH2_EAI?si=oarCTCV-M73lQj9j', '_blank');
                }} />

            </div>

                
            <div className="new-option padding-40-top">
                <p>
                Piedzīvot sēņu audzēšanas prieku mājās var ikviens – gan bērni, gan pieaugušie, tomēr, lai tas izdotos, ir ļoti svarīgi precīzi sekot šai audzēšanas instrukcijai. Ja procesā rodas kādi jautājumi vai šaubas, noteikti sazinieties ar mums un sūtiet bildes – mums ļoti rūp, lai jums izaug laimīgas sēnes! 
                <br /><br />
                
                Daudzas atbildes uz sēņu audzēšanas jautājumiem atrodamas arī mūsu mājaslapas sadaļā “Jautājumi”.
                </p>
            </div>

            <div className="new-option">
                <h3 className='padding-40-top'>Sēņu audzēšanas process</h3>

                <div className="card-text padding-20-top">
                    <p className='bold'>Iegriez X</p>
                    <p className='padding-10-top'>
                        Ar tīru, asu papīra nazi micēlija bloka priekšpusē (pāris centimetrus zem uzlīmes) iegriez 5-7cm platu X formas griezumu. <b>Izņēmums ir Ežu dižadatene</b>, kurai ir jāgriež tikai 2-3cm X. Griezuma vietu <b>neaiztikt ar pirkstiem un plastmasa NAV jāatloka vaļā</b> – to izdarīs pati sēne, kad būs tam gatava. 
                    </p>
                    <p>Kārtīgi apsmidzini griezuma vietu. </p>
                </div>
                <div className="card-text padding-20-top">
                    <p className='bold'>Uzstādi siltumnīcu</p>
                    <p className='padding-10-top'>
                        Paņem līdzi iedoto plastmasas maisu ar caurumiem un liec to virsū micēlija blokam tā, <b>lai sēnei ir brīva vieta, kur augt</b> - maisa šaurajam sānam jābūt piespiestam pie bloka muguras. Papildus mitrumam, vari micēlija bloku novietot uz plakana šķīvja, kur ieliets ūdens.
                    </p>
                </div>
                <div className="card-text padding-20-top">
                    <p className='bold'>Uzturi mitrumu</p>
                    <p className='padding-10-top'>
                        Ar ūdens pulverizatoru miglas režīmā 2-4 reizes dienā kārtīgi apsmidzini siltumnīcas maisa iekšpusi un pašu bloku. Lai izvairītos no pārlieku liela mitruma, <b>nesmidzini ūdeni tieši uz pašām sēnēm</b>. Kad sēnes paaugušās, vari maisu ņemt nost un turpināt smidzināt sēnes 2-4 reizes dienā. Bet ja gaiss ir ļoti sauss, mēs iesakām turpināt audzēt sēnes šajā siltumnīcā.
                    </p>
                </div>
                <div className="images padding-20-top">
                    <div className="bloka-image">
                        <img src={bloka_pirmais} alt="" />
                    </div>
                    <div className="bloka-image">
                        <img src={bloka_otrais} alt="" />
                    </div>
                    <div className="bloka-image">
                        <img src={bloka_tresais} alt="" />
                    </div>
                </div>
                
            </div>

            <div className="instrukcijas-teksti padding-20-bottom" >
                <div className="instrukcijas-teksts padding-20-top">
                    <h3 className='padding-20-top'>Kur audzēt?</h3>
                    <div className="instrukcija-content">
                        <div className="instrukcija-content-text">
                            <p className='padding-20-top'>
                                Sēņu augšanai jācenšas radīt līdzīgus apstākļus kā tām augot mežā - nepieciešams liels mitrums un daudz svaiga gaisa. Līdz ar to, piemērota vieta ir virtuve, blakus izlietnei vai pat vannas istaba. Jāizvairās no tiešiem saules stariem un kondicionieriem, kas pūš virsū sausu gaisu.                             
                            <br /><br />
                                Lai palīdzētu radīt labvēlīgāku mikroklimatu, bloku var novietot uz plakana šķīvja, kurā ieliets tīrs ūdens.
                            </p>
                        </div>
                    </div>
                    
                </div>
            

                <div className="instrukcijas-teksts padding-20-top">
                <h3 className='padding-20-top'>Kāda temperatūra ir vēlama?</h3>
                    <div className="instrukcija-content">
                        <div className="instrukcija-content-text padding-20-top">
                            {mobile ?
                                <ul>
                                    <ul>
                                        <li>Pelēkā austersēne → 13-22 °C</li>
                                        <li>Zilā austersēne → 13-24 °C</li>
                                        <li>Gļotainā zvīņene → 15-19 °C</li>
                                        <li>Melnā pērļu austersēne → 15-20 °C</li>
                                        <li>Ežu dižadatene → 15-25 °C</li>
                                        <li>Rozā austersēne → 21-27 °C</li>
                                    </ul>
                                </ul>
                            :
                                <ul>
                                    <li>
                                        Pleurotus Ostreatus (Pelēkā austersēne) → 13-22 °C
                                    </li>
                                    <li>
                                        Pleurotus Ostreatus (Zilā austersēne) → 13-24 °C
                                    </li>
                                    <li>
                                        Pholiota Adiposa (Gļotainā zvīņene) → 15-19 °C
                                    </li>
                                    <li>
                                        Pleurotus Ostreatus (Melnā pērļu austersēne) → 15-20 °C
                                    </li>
                                    <li>
                                        Hericium Erinaceus (Ežu dižadatene) → 15-25 °C
                                    </li>
                                    <li>
                                        Pleurotus Djamor (Rozā austersēne) → 21-27 °C
                                    </li>
                                </ul>
                            }
                            <p className='padding-20-top'>(Piezīme: tās visas spēj paciest arī nelielas novirzes no šīs temperatūras.)</p>
                        </div>
                    </div>
                    
                </div>

                <div className="instrukcijas-teksts padding-20-top">
                    <div className="instrukcija-content-full">
                        <h3 className='padding-20-top'>Kad sēnes būs gatavas?</h3>
                        <p className='padding-20-top'>
                        Pirmos sēņu mazuļus, atkarībā no sugas, vari gaidīt 5 - 30 dienu laikā. Ātrāku sēņu parādīšanos veicina temperatūras svārstības. Ja sēnes ilgstoši neaug, iesakām bloku uz nakti atstāt pie atvērta loga vai pat ledusskapī (izņēmums ir Rozā austersēne, kura necieš temperatūru kas zemāka par 6° C). 
                        <br /><br />
                        Kad sēnes sāk augt, tās var sasniegt briedumu pat 5 dienu laikā, tomēr augšanas ātrums ir atkarīgs gan no sugas, gan temperatūras.
                        <br /><br />
                        Austersēņu gatavības pazīmes – sēnes pārstāj augt lielākas, cepurītes malas sāk iztaisnoties uz augšu un sēnes kļūst bālākas.
                        <br /><br />
                        Ežu dižadatenēm nogatavojoties tām sāk parādīties garenas skaras. Kad tās kļūst izteiksmīgas, ežu dižadateni var novākt. Skaras var izaugt pat vairākus centimetrus garas, tomēr tad tās jau metīs sporas, kuras lielos daudzumos ieelpot nav ieteicams.
                        <br /><br />
                        Gļotainās zvīņenes ir gatavas, kad cepurītes sāk vērties vaļā vai ir nupat atvērušās. 
                        </p>
                    </div>
                </div>

                <div className="instrukcijas-teksts padding-20-top">
                    <div className="instrukcija-content-full">
                        <h3 className='padding-20-top'>Kā novākt ražu?</h3>
                        <p className='padding-20-top'>
                            Kad lielākā daļa sēņu ir gatavas, laiks vākt nost ražu, tai skaitā arī mazās sēnītes, kuras lielākas tāpat vairs neaugs. Pats process ir ātrs un viegls – ar rokām satver sēņu čemuru un pagrozi to pa kreisi un pa labi, līdz sēnes atdās no substrāta. Pēc tam ar pirkstiem uzmanīgi nolasi nost visas mazās sēnītes, citādi tās sāks bojāties un pelēt.
                            <br /><br />
                            <b>Pēc sēņu ražas novākšanas</b>, bloks būs zaudējis daudz ūdens, tāpēc vari to padzirdīt, iemērcot bloku ar griezuma vietu uz leju tīrā ūdenī. Ežu dižadateni drīkst mērcēt tikai 5-10minūtes, taču citas sugas vari mērcēt 20 minūtes. Pēc tam lieko ūdeni no iepakojuma iztecini laukā. Liec bloku atpakaļ siltumnīcā, smidzi un gaidi nākamo ražu. Kopā vari sagaidīt 2-3 ražas, līdz micēlijs būs iztērējis lielāko daļu barības vielas, taču iespējams sagaidīt arī vairāk.                        </p>
                    </div>
                </div>

                <div className="instrukcijas-teksts padding-20-top">
                    <div className="instrukcija-content-full">
                        <h3 className='padding-20-top'>Kā tās gatavot?</h3>
                        <p className='padding-20-top'>
                            Visas mūsu piedāvājumā esošās sēnes pirms lietošanas uzturā nepieciešams termiski apstrādāt. Tās var vienkārši apcept sviestā, pievienojot sāli, piparus, ķiplokus pēc izvēles. Tomēr mūsu mīļākais veids, kā ēst pašu audzētās sēnes ir sēņu karbonādes un nageti!  Arī to pagatavošana ir pavisam ātra un vienkārša, taču rezultāts – neticami gards! Sakul olu ar sāli, pipariem un pēc izvēles pievieno kūpināto papriku vai ķiplokus. Sagriez sēnes kumosa izmēra gabaliņos. Ieber visas sēnes bļodā un apvīļā olā, uzber pa virsu miltus un atkal apmaisi. Liec uz sakarsētas pannas ar eļļu, cep līdz tās ir zeltaini brūnas un kraukšķīgas. Piezīme: Pelēko austersēņu kātiņi pie pašas “saknes” mēdz būt sīksti, tāpēc tos iesaku griezt plānās 2mm šķēlītēs. 
                        </p>
                    </div>
                </div>

                <div className="instrukcijas-teksts padding-20-top">
                    <h3 className='padding-20-top'>Vai ir dzīve pēc ražas?</h3>
                    <div className="instrukcija-content">
                        
                        <div className="instrukcija-content-text">
                            <p className='padding-20-top'>
                                Kad no bloka sēnes vairs neaug, micēlijs nav miris, tam vienkārši nepietiek barības vielu, lai radītu jaunas sēnes. Bloka substrātu var iejaukt dārza kompostā vai ierakt siltumnīcā/dobē un cerēt uz atkārtotu sēņu ražu, turklāt micēlijs būs arī lielisks palīgs augsnes uzlabošanā.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="instrukcijas-teksts padding-20-top">
                    <p>Seko mums Instagram <a href="https://www.instagram.com/nora.spora/" target="_blank" rel="noreferrer">@nora.spora</a></p>
                </div>
            </div>

        </div>
        
    );
}

export default Instrukcija;