import React from 'react'
import MenuItem from '../style-components/MenuItem'

function HeaderAdmin() {
  return (
    <div className='header-admin'>
        <MenuItem text='Logout' link='/logout' />
        <MenuItem text='Jautājumi' link='/edit-questions' />
        <MenuItem text='Produkti' link='/edit-products' />
        <MenuItem text='Pasūtījumi' link='/edit-orders' />
        <MenuItem text='Kuponi' link='/edit-coupons' />
    </div>
  )
}

export default HeaderAdmin
