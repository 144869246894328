import eikeni from '../img/eikeni.jpg'
import { Helmet } from 'react-helmet'

function AboutUs() {
  return (
    <div className='about-us padding-10-bottom'>
      <Helmet>
        <title>Nora Spora - Par mums</title>
        <meta name="description" content="Nora Spora piedāvā plašu klāstu sēņu audzēšanas komplektu, tostarp rozā un pelēkās austersēnes, ežu dižadatenes, gļotainās zvīņenes un drīzumā arī citas." />
        <meta name="keywords" content="nora, spora, sporas, noraspora, supersenes, supersēnes, super, sēnes, maple garden, kļavas dārzs, svaigas sēnes, Trubenieki, Eco Fungus, Austersēnes, Sēņu laboratorija Spora, baravikas, gailenes, bekas, micēlijs, micēlija tapiņas, micēlija dībeļi, rozā austersēnes, austeres, pelēkās austersēnes, zilās austersēnes, lions mane, ežu dižadatene, reiši, reishi, shitake, šitake, šitaki, dzeltenās austersēnes, zeltainās austersēnes, zelta austersēnes, dāvana, dāvanas, hobijs, ekoloģisks, bioloģisks, bio, eko, dabīgs, latvijas sēnes" />
      </Helmet>
      <div className="two-column-flex">
        <div className="left">
          <div className="about-us-media">
            <img src={eikeni} alt="Eikeni" />
          </div>
        </div>
        <div className="right">
          <div className="about-us-content">
            <h2 className='light'>Par mums</h2>
            <p>
              Sveiki, jaunie sēņu draugi!
              <br /><br />
              Te Ance un Raivis Eikeni. NORA SPORA ir mūsu ģimenes uzņēmums, kura ideja dzimusi reizē ar meitiņas Noras nākšanu pasaulē. Mūs vieno mīlestība pret dabu un tās procesiem, tādēļ darbotes ar micēliju un sēņu audzēšanu mums sagādā milzīgu prieku, ko vēlamies nodot tālāk arī jums. Ance ir māksliniece, savukārt Raivis līdz šim strādājis finanšu un projektu vadības jomā, tomēr, dodoties bērna kopšanas atvaļinājumā un pavadot daudz laiku kopā pie dabas, sākām sapņot par klātesošāku dzīvi, strādājot kopā pie kaut kā, kas atbilst mūsu dzīves uzskatiem un vērtībām – dzīvot lēnāk, apzinātāk, nepazust ikdienas skrējienā. Redzēt kā meitiņa aug un mainās ir ļoti līdzīgi tam, cik ātri aug sēnes un abus piedzīvot savu acu priekšā ir patiesa laime!
              <br /><br />
              Sēņu audzēšana mājās ikvienam sniedz iespēju piedzīvot kaut ko brīnumainu un kā lieliska pievienotā vērtība ir iespēja papildināt savu uzturu ar kaut ko svaigu, garšīgu, pašu audzētu un uzturvielām bagātu. Mēs ticam, ka pasaule būtu vismaz par pāris sporām labāka, ja visi audzētu sēnes. Audz arī Tu kopā ar NORA SPORA!
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
