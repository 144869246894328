import { configureStore } from '@reduxjs/toolkit';
import productReducer from '../features/products/productSlice';
import orderReducer from '../features/orders/orderSlice';
import shippingReducer from '../features/shipping/shippingSlice';
import questionReducer from '../features/questions/questionSlice';
import authReducer from '../features/auth/authSlice';
import couponReducer from '../features/coupons/couponSlice';

export const store = configureStore({
  reducer: {
    product: productReducer,
    order: orderReducer,
    shipping: shippingReducer,
    question: questionReducer,
    auth: authReducer,
    coupon: couponReducer,
  },
});
